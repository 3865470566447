import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	Paper,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
} from "@mui/material";
import { GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
// cmp
import LogDetailItem from "../cmp/logs/LogDetailItem";
// contexts
import { useUserData } from "../context/UserDataContext";
// services
import Gupport from "../services/gupport";
import { icons } from "@local/theme";
// types
import type { GridRowId, GridColDef } from "@mui/x-data-grid";
import type { CmdGetLogs } from "../types/gupport";
import type { GLog } from "../types/log";

const GLogPage = () => {
	const { t } = useTranslation();

	const { ready } = useUserData();

	const [loading, setLoading] = useState(true);
	const [showLogDialog, setShowLogDialog] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [logs, setLogs] = useState<Array<GLog>>([]);
	const [logData, setLogData] = useState<GLog | undefined>(undefined);

	const handleShowJsonClick = useCallback((id: GridRowId) => (
		() => {
			setLogData(logs.find((log) => (log.id === id)));
			setShowLogDialog(true);
		}
	), [logs]);

	const columns = useMemo(() => ([
		{
			field: "logText",
			headerName: t("glogs.text"),
			flex: 6,
		},
		{
			field: "_timestamp",
			headerName: t("glogs.timestamp"),
			flex: 1,
			renderCell: (params) => (<time dateTime={new Date(params.value).toISOString()}>{new Date(params.value).toLocaleString()}</time>),
			valueFormatter: (value) => (new Date(value).toISOString()),
		},
		{
			field: "level",
			headerName: t("glogs.level"),
			flex: 1,
		},
		{
			field: "actions",
			headerName: "Raw",
			type: "actions",
			flex: 1,
			getActions: (params) => ([
				<GridActionsCellItem
					key={params.id}
					label="Raw"
					icon={<icons.RawOn />}
					onClick={handleShowJsonClick(params.id)}
				/>,
			]),
		},
	] as const satisfies ReadonlyArray<GridColDef<GLog>>), [t, handleShowJsonClick]);

	useEffect(() => {
		if (ready && Gupport.getLogs) {
			setLoading(true);
			const cmd = {
				action: "getLogs",
			} as const satisfies CmdGetLogs;
			Gupport.send(cmd, (_error, msg) => {
				if (!_error && msg?.payload.status === "ok") {
					setError(null);
					setLogs(msg.payload.data);
				} else {
					setError(t("glogs.msg"));
					setLogs([]);
				}
				setLoading(false);
			});
		}
	}, [ready, t]);

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<>
			<Paper>
				<DataGrid
					loading={loading}
					columns={columns}
					rows={logs}
					sx={{
						"& .MuiDataGrid-actionsCell svg": {
							width: "26px",
							height: "26px",
						},
					}}
				/>
			</Paper>
			<Dialog
				id="dlg-log-details"
				fullWidth={true}
				open={showLogDialog}
				onClose={() => (setShowLogDialog(false))}
			>
				<DialogTitle>{t("glogs.dialogTitle")}</DialogTitle>
				<DialogContent>
					<List disablePadding={true}>
						<LogDetailItem label={t("glogs.channel")} value={logData?.channel} />
						<LogDetailItem label={t("glogs.connected")} value={logData?.connected} />
						<LogDetailItem label={t("glogs.handler")} value={logData?.handler} />
						<LogDetailItem label={t("glogs.ip")} value={logData?.ip} />
						<LogDetailItem label={t("glogs.node")} value={logData?.node} />
						<LogDetailItem label={t("glogs.payload")} value={logData} />
					</List>
				</DialogContent>
				<DialogActions>
					<Button color="inherit" className="btn-dlg-action-close" onClick={() => (setShowLogDialog(false))}>{t("dialog.close")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default GLogPage;
