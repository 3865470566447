import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
	CircularProgress,
	Button,
	Card,
	CardHeader,
} from "@mui/material";
// cmp
import withNavigateAndParams from "../cmp/withNavigateAndParams";
import EditableRowInfo from "../cmp/table-editor/custom-editor/EditableRowInfo";
import TableEditorTabs from "../cmp/table-editor/TableEditorTabs";
import ReviewDialog from "../cmp/table-editor/custom-editor/ReviewDialog";
import DeleteDialog from "../cmp/table-editor/DeleteDialog";
import WarningDialog from "../cmp/table-editor/custom-editor/WarningDialog";
import ErrorDialog from "../cmp/table-editor/custom-editor/error-dialog";
import Svg from "../cmp/svg";
// services
import User from "../services/user";
import Gupport from "../services/gupport";
import { muiTheme } from "@local/theme";
// types
import type { WithTranslation } from "react-i18next";
import type { CmdDeleteTable, CmdGetTable, CmdUpdateTable } from "../types/gupport";
import type { ReactRouterProps } from "../types/misc";

type Props = Readonly<WithTranslation & ReactRouterProps>;

type State = {
	loading: boolean;
	openRemoveDialog: boolean;
	openWarningDialog: boolean;
	saveError: any | null; // TODO
	enableReviewBtn: boolean;
	openReviewDialog: boolean;
	tableItemSelected: any | null; // TODO
	reviewData: Array<any>; // TODO
};

class CustomTEditorPage extends Component<Props, State> {

	#tableData: Array<any> = []; // TODO

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: true,
			openRemoveDialog: false,
			openWarningDialog: false,
			saveError: null,
			enableReviewBtn: false,
			openReviewDialog: false,
			tableItemSelected: null,
			reviewData: [],
		};

		this.handleBack = this.handleBack.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleRemoveClick = this.handleRemoveClick.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.onActionJsonUpdate = this.onActionJsonUpdate.bind(this);
		this.openRemoveDialog = this.openRemoveDialog.bind(this);
		this.showReviewPanel = this.showReviewPanel.bind(this);
		this.disableSaveBtn = this.disableSaveBtn.bind(this);
		this.handleConfirmClick = this.handleConfirmClick.bind(this);
		this.handleDialogCloseClick = this.handleDialogCloseClick.bind(this);
		this.handleErrorDialogClose = this.handleErrorDialogClose.bind(this);
	}

	override componentDidMount() {
		if (Gupport.ready) {
			this.fetchTable("rocid_dict");
		} else {
			Gupport.once("ready", () => {
				this.fetchTable("rocid_dict");
			});
		}
	}

	fetchTable(tableId) {
		this.setState({
			loading: true,
			// error: null
		}, () => {
			const cmd = {
				action: "getTable",
				tableName: tableId,
			} as const satisfies CmdGetTable;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.#tableData = msg.payload.data;
					this.setState({
						loading: false,
						tableItemSelected: msg.payload.data.find((table) => (table.id.string_key === this.props.params.stringKey)),
					});
				} else {
					this.setState({
						loading: false,
						tableItemSelected: null,
					});
				}
			});
		});
	}

	openRemoveDialog() {
		this.setState({
			openRemoveDialog: true,
		});
	}

	handleDialogCloseClick() {
		this.setState({
			openRemoveDialog: false,
			openWarningDialog: false,
			openReviewDialog: false,
			saveError: null,
		});
	}

	handleErrorDialogClose() {
		this.setState({
			saveError: null,
		});
	}

	disableSaveBtn() {
		this.setState({
			enableReviewBtn: false,
		});
	}

	showReviewPanel() {
		this.setState({
			openReviewDialog: true,
		});
	}

	onActionJsonUpdate(json, reviewData) {
		this.setState((prevState) => ({
			enableReviewBtn: (reviewData ?? []).length > 0,
			tableItemSelected: {
				id: prevState.tableItemSelected.id,
				data: json,
			},
			reviewData: reviewData ?? [],
		}));
	}

	handleTabChange(/*tabId*/) {
		this.setState({
			enableReviewBtn: false,
			tableItemSelected: this.#tableData.find((table) => (table.id.string_key === this.props.params.stringKey)),
			reviewData: [],
		});
	}

	handleSaveClick(backToList) {
		const { t } = this.props;
		// this.setState({
		// 	saving: true
		// });

		const cmd = {
			action: "updateTable",
			tableName: "rocid_dict",
			data: [{
				id: this.state.tableItemSelected.id.string_key,
				data: this.state.tableItemSelected.data,
			}],
		} as const satisfies CmdUpdateTable;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				if (backToList) {
					this.setState({
						enableReviewBtn: false,
					});
					// TODO: check async navigate
					void this.props.navigate("/teditor");
				} else {
					this.setState({
						enableReviewBtn: false,
						openReviewDialog: false,
						reviewData: [],
					});
					this.fetchTable("rocid_dict");
				}
			} else {
				this.setState({
					saveError: t(`customtEditor.errors.${msg.payload.data ?? error.message}`) ?? t("customtEditor.errors.general"),
				});
			}
		});
	}

	handleRemoveClick() {
		const cmd = {
			action: "deleteTable",
			tableName: "rocid_dict",
			data: [{
				id: this.state.tableItemSelected.id.string_key,
			}],
		} as const satisfies CmdDeleteTable;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				// TODO: check async navigate
				void this.props.navigate("/teditor");
			}
		});
	}

	handleBack() {
		if (this.state.reviewData.length > 0) {
			this.setState({
				openWarningDialog: true,
			});
		} else {
			// TODO: check async navigate
			void this.props.navigate("/teditor");
		}
	}

	handleConfirmClick() {
		this.setState({
			openWarningDialog: false,
		}, () => {
			// TODO: check async navigate
			void this.props.navigate("/teditor");
		});
	}

	override render() {
		const { t } = this.props;

		if (this.state.loading) {
			return <CircularProgress />;
		}

		const hasWriteAccess = User.user?.table_write.includes("rocid_dict") ?? false;

		return (
			<>
				{!hasWriteAccess &&
					<Card style={{ marginBottom: "24px", color: muiTheme.palette.text.primary, backgroundColor: muiTheme.palette.primary.main }}>
						<CardHeader title={t("customtEditor.errors.rbac_not_authorized")} avatar={<Svg src="messages/error.svg" />} />
					</Card>
				}
				<EditableRowInfo selectedItem={this.state.tableItemSelected} />
				<TableEditorTabs
					selectedItem={this.state.tableItemSelected.data}
					onDataStringInvalid={this.disableSaveBtn}
					onActionJsonUpdate={this.onActionJsonUpdate}
					onTabChange={this.handleTabChange}
				/>
				<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px", padding: "0 12px" }}>
					<Button
						variant="contained"
						disabled={!hasWriteAccess}
						onClick={this.openRemoveDialog}
						style={{ marginRight: "5px" }}
					>
						{t("customtEditor.delete")}
					</Button>
					<Button
						variant="contained"
						disabled={!this.state.enableReviewBtn || !hasWriteAccess}
						onClick={this.showReviewPanel}
						style={{ marginLeft: "5px" }}
					>
						{t("customtEditor.review")}
					</Button>
				</div>
				<ReviewDialog
					reviewData={this.state.reviewData}
					open={this.state.openReviewDialog}
					onSave={this.handleSaveClick}
					onClose={this.handleDialogCloseClick}
				/>
				<DeleteDialog
					open={this.state.openRemoveDialog}
					handleRemoveClick={this.handleRemoveClick}
					handleClose={this.handleDialogCloseClick}
				/>
				<WarningDialog
					open={this.state.openWarningDialog}
					handleConfirmClick={this.handleConfirmClick}
					handleClose={this.handleDialogCloseClick}
				/>
				{this.state.saveError &&
					<ErrorDialog
						errorMsg={this.state.saveError}
						onClose={this.handleErrorDialogClose}
					/>
				}
			</>
		);
	}

}

export default withTranslation()(withNavigateAndParams(CustomTEditorPage));
