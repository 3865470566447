/* eslint-disable i18next/no-literal-string */
import { Paper } from "@mui/material";
// cmp
import Image from "../cmp/Image";
// service
// @ts-expect-error: window.appInfo
import AppInfo from "@local/appInfo";

const AboutPage = () => (
	<Paper style={{ padding: "16px" }}>
		<Image
			src="navdrawer-logo.svg"
			sx={{ margin: "auto", display: "block", maxHeight: "94px", width: "100%" }}
		/>
		<div style={{ textAlign: "center", marginTop: "24px" }}>Build: {AppInfo.build}</div>
	</Paper>
);

export default AboutPage;
