import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
// cmps
import ServerOptions from "../cmp/ServerOptions";
// import Glient from "../services/glient";

const SettingsPage = () => {
	const { t } = useTranslation();

	return (
		<>
			<Typography>{t("settings.title")}</Typography>
			<ServerOptions />
		</>
	);
};

export default SettingsPage;
