import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { Select, MenuItem, ListItemText } from "@mui/material";
// cmps
import CenterCircularProgress from "../cmp/CenterCircularProgress";
import GatewayStatChart from "../cmp/gateway/GatewayStatChart";
import GatewayDeviceStatChart from "../cmp/gateway/GatewayDeviceStatChart";
// contexts
import { useUserData } from "../context/UserDataContext";
// services
import Gupport from "../services/gupport";
import Responsive from "../services/responsive";
// types
import type { ReadonlyDeep } from "type-fest";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { GetGatewayStatsData, CmdGetDeviceStats, CmdGetGatewayStats, CmdGetTable, GatewayBucketData, DeviceBucketData, GetDeviceStatsData } from "../types/gupport";
import type { RocIdData } from "../types/roc-table";

const GRID_SCREEN_COLUMNS = {
	md: true,
	sm: false,
	xs: false,
} as const;

const WelcomePage = () => {
	const navigate = useNavigate();

	const { ready } = useUserData();

	const rocIdTableDataRef = useRef<Array<RocIdData> | undefined>(undefined);

	const [loading, setLoading] = useState(true);
	const [container, setContainer] = useState(Boolean(Responsive.getScreenSizes().find((screen) => (GRID_SCREEN_COLUMNS[screen])) ?? GRID_SCREEN_COLUMNS.xs));
	const [deviceStatData, setDeviceStatData] = useState<ReadonlyDeep<GetDeviceStatsData> | null>(null);
	const [selectedDeviceStat, setSelectedDeviceStat] = useState<ReadonlyDeep<DeviceBucketData> | null>(null);
	const [gatewayStatData, setGatewayStatData] = useState<ReadonlyDeep<GetGatewayStatsData> | null>(null);
	const [selectedGatewayStat, setSelectedGatewayStat] = useState<ReadonlyDeep<GatewayBucketData> | null>(null);

	useEffect(() => {
		if (selectedGatewayStat && deviceStatData) {
			const selectedBucket = deviceStatData.channel.buckets.find((bucket) => (bucket.val === selectedGatewayStat.val));
			if (selectedBucket) {
				setSelectedDeviceStat(selectedBucket);
			}
		}
	}, [selectedGatewayStat, deviceStatData]);

	useEffect(() => {
		const handleScreenChanged = () => {
			setContainer(Boolean(Responsive.getScreenSizes().find((screen) => (GRID_SCREEN_COLUMNS[screen])) ?? GRID_SCREEN_COLUMNS.xs));
		};

		Responsive.on("screenChanged", handleScreenChanged);

		if (ready && Gupport.getGatewayStats) {
			const tasks = [
				new Promise<void>((resolve, reject) => {
					const cmd = {
						action: "getTable",
						tableName: "rocid_dict",
					} as const satisfies CmdGetTable;
					Gupport.send(cmd, (error, msg) => {
						if (!error && msg?.payload.status === "ok") {
							rocIdTableDataRef.current = msg.payload.data.map((entry) => (entry.data));
							resolve();
						} else {
							reject(error);
						}
					});
				}),
				new Promise<void>((resolve, reject) => {
					const cmd = {
						action: "getGatewayStats",
					} as const satisfies CmdGetGatewayStats;
					Gupport.send(cmd, (error, msg) => {
						if (!error && msg?.payload.status === "ok") {
							const gatewayStat = msg.payload.data;
							const buckets = gatewayStat.channel.buckets;

							setGatewayStatData(gatewayStat);
							setSelectedGatewayStat(buckets.length > 0 ? buckets[0] ?? null : null);
							resolve();
						} else {
							reject(error);
						}
					});
				}),
				new Promise<void>((resolve, reject) => {
					const cmd = {
						action: "getDeviceStats",
					} as const satisfies CmdGetDeviceStats;
					Gupport.send(cmd, (error, msg) => {
						if (!error && msg?.payload.status === "ok") {
							setDeviceStatData(msg.payload.data);
							setSelectedDeviceStat(null);
							resolve();
						} else {
							reject(error);
						}
					});
				}),
			];

			void (async () => {
				try {
					await Promise.all(tasks);
					setLoading(false);
				} catch (_error) {
					setLoading(false);
					console.warn("Stats loading failed", _error);
				}
			})();
		} else {
			// TODO: check async navigate
			void navigate("/gateways");
		}

		return () => {
			Responsive.off("screenChanged", handleScreenChanged);
			rocIdTableDataRef.current = undefined;
		};
	}, [navigate, ready]);

	const handleSelect = (event: SelectChangeEvent) => {
		const currentSelectedGatewayStat = gatewayStatData?.channel.buckets.find((bucket) => (bucket.val === event.target.value)) ?? null;
		const currentSelectedDeviceStat = deviceStatData?.channel.buckets.find((bucket) => (bucket.val === event.target.value)) ?? null;

		setSelectedGatewayStat(currentSelectedGatewayStat);
		setSelectedDeviceStat(currentSelectedDeviceStat);
	};

	if (loading) {
		return <CenterCircularProgress />;
	}

	return (
		<>
			<div className="page-header-padding">
				{(selectedGatewayStat && gatewayStatData && gatewayStatData.channel.buckets.length > 1) &&
					<Select
						name="channel"
						value={selectedGatewayStat.val}
						style={{ width: "45%" }}
						onChange={handleSelect}
					>
						{
							gatewayStatData?.channel.buckets.map((bucket, index) => (
								<MenuItem key={index} value={bucket.val}>
									<ListItemText primary={bucket.val} />
								</MenuItem>
							))
						}
					</Select>
				}
				<div>
					<GatewayStatChart container={container} selectedGatewayStat={selectedGatewayStat} />
					<GatewayDeviceStatChart container={container} selectedDeviceStat={selectedDeviceStat} rocIdTableData={rocIdTableDataRef.current} />
					<br style={{ clear: "both" }} />
				</div>
			</div>
			{/* <Paper component="article">
				<iframe
					src={`${gupportWsUrl.replace("wss://", "https://")}/stats/consoles/glient.html`}
					width="100%"
					height="100%"
					style={{ display: "block", border: "none", height: "calc(100vh - 85px)" }}
				/>
			 /> */}
		</>
	);
};

export default WelcomePage;
