import { useState, createRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
	Paper,
	Button,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	InputBase,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
// cmp
// import Glient from "../services/glient";
import { icons } from "@local/theme";
// types
import type { ChangeEvent } from "react";

interface FileData {
	file: File;
	text: string;
	data: Array<Data> | undefined;
	error: Error | undefined;
}
interface Data {
	key: string;
	value: boolean | number | string | object | Array<any>;
	invalidType: boolean;
	path: Array<string>;
}

type Output = {
	severity: string;
	text: string;
	fileIndex?: number;
	lineString?: string;
};

type LineDetails = {
	lineNumber: number;
	lineString: string;
};

const MessageBlock = styled("code")({
	display: "block",
	margin: "2px 0",
});

const CodeBlock = styled("code")(({ theme }) => ({
	display: "inline-block",
	padding: "1px 2px",
	borderRadius: "4px",
	backgroundColor: (theme.palette.mode === "dark") ? grey[800] : grey[200],
	color: theme.palette.text.primary,
	wordBreak: "break-all",
}));

const LocalizationPage = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	const referenceFileRef = createRef<HTMLInputElement>();
	const updateFileRef = createRef<HTMLInputElement>();

	const [hasReferenceFileError, setHasReferenceFileError] = useState(false);
	const [referenceFile, setReferenceFile] = useState<FileData | undefined>(undefined);

	const [haveUpdateFilesError, setHaveUpdateFilesError] = useState(false);
	const [updateFiles, setUpdateFiles] = useState<Array<FileData>>([]);

	const [filesDiff, setFilesDiff] = useState<Array<Output>>([]);
	const filesHeadingShown: Array<number> = [];

	const getDataFromText = (fileText: string): Array<Data> => {
		const data: Array<Data> = [];
		const checkObj = (obj: object, path: Data["path"]) => {
			for (const key in obj) {
				if (obj.hasOwnProperty(key)) {
					const value = obj[key];
					const isObject = typeof value === "object" && value !== null && !Array.isArray(value);
					const newPath = [...path, key];
					data.push({
						key: key,
						value: isObject ? Object.keys(value) : value,
						invalidType: !isObject && typeof value !== "string",
						path: newPath,
					});
					if (isObject) {
						checkObj(value, newPath);
					}
				}
			}
		};
		checkObj(JSON.parse(fileText), []);
		return data;
	};

	const handleFilesChanged = (type: "reference" | "update", files: FileList | null) => {
		if (files) {
			const tasks = Array.from(files).map((file) => (
				new Promise<FileData>((resolve, reject) => {
					const fileReader = new FileReader();

					fileReader.addEventListener("load", (event) => {
						const fileText = event.target?.result as string;
						let data: Array<Data> | undefined = undefined;
						let error: Error | undefined = undefined;
						try {
							data = getDataFromText(fileText);
						} catch (err) {
							error = err;
						}
						resolve({
							file: file,
							text: fileText,
							data: data,
							error: error,
						});
					});
					fileReader.addEventListener("error", (event) => {
						reject(event.target?.error);
					});
					fileReader.addEventListener("abort", (event) => {
						reject(event.target?.error);
					});
					fileReader.readAsText(file, "UTF-8");
				})
			));

			Promise.all(tasks).then((filesData) => {
				const errors = filesData.map((fileData) => (fileData.error));
				const hasError = errors.some((error) => (Boolean(error)));
				if (type === "reference") {
					setHasReferenceFileError(hasError);
					setReferenceFile(filesData[0]);
				} else {
					setHaveUpdateFilesError(hasError);
					setUpdateFiles(filesData);
				}
				setFilesDiff(hasError ? [{ severity: "error", text: String(errors) }] : []);
			}).catch((error) => {
				if (type === "reference") {
					setHasReferenceFileError(true);
					setReferenceFile(undefined);
				} else {
					setHaveUpdateFilesError(true);
					setUpdateFiles([]);
				}
				setFilesDiff([{ severity: "error", text: String(error) }]);
			});
		}
	};

	const hasOpenBrackets = (chars: string) => {
		let openBrackets = 0;
		for (const char of chars) {
			if (char === "{") {
				openBrackets++;
			} else if (char === "}") {
				openBrackets--;
			}
		}
		return openBrackets > 0;
	};

	const getAllIndexes = (chars: string, searchChar: string) => {
		const indexes: Array<number> = [];
		let index = -1;
		while ((index = chars.indexOf(searchChar, index + 1)) >= 0) {
			indexes.push(index);
		}
		return indexes;
	};

	const getLineDetailsJson = (fileText: string, searchPath: Array<string>): LineDetails => {
		let objectDepth = -1;
		let pathIndex = 0;
		let regex = new RegExp(`"${searchPath[pathIndex] as string}"\\s*:`);

		const lines = fileText.split("\n");
		for (const [index, line] of lines.entries()) {
			if (objectDepth === pathIndex && regex.test(line)) {
				if (pathIndex === searchPath.length - 1) {
					return { lineNumber: index + 1, lineString: line };
				} else if (line.includes("{")) {
					pathIndex++;
					regex = new RegExp(`"${searchPath[pathIndex] as string}"\\s*:`);
				}
			}
			if (line.includes("}")) {
				objectDepth--;
			}
			if (line.includes("{")) {
				objectDepth++;
			}
		}
		return { lineNumber: 0, lineString: "" };
	};

	const handleTestClick = () => {
		const newFilesDiff: Array<Output> = [];
		let numberOfDiffernces = 0;
		let warningShown = false;
		updateFiles.forEach((fileData, fileDataIndex) => {
			(fileData.data as Array<Data>).forEach((data, dataIndex) => {
				if (Array.isArray(data.value) && data.value.length === 0) {
					const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
					const text = `[${lineNumber}] Has empty object at path: "${data.path.join(".")}"`;
					newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "warning", lineString: lineString });
				} else if (typeof data.value === "string") {
					if (data.value.length === 0) {
						const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
						const text = `[${lineNumber}] Has empty value at path: "${data.path.join(".")}"`;
						newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "warning", lineString: lineString });
					} else {
						if (data.value.includes("{") || data.value.includes("}")) {
							if (hasOpenBrackets(data.value)) {
								const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
								const text = `[${lineNumber}] Has missing brackets at path: "${data.path.join(".")}"`;
								newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "error", lineString: lineString });
							} else {
								const leftBracketAllIndeces = getAllIndexes(data.value, "{");
								const rightBracketAllIndeces = getAllIndexes(data.value, "}");
								leftBracketAllIndeces.forEach((leftBracketIndex) => {
									if (data.value[leftBracketIndex + 1] === " ") {
										const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
										const text = `[${lineNumber}] Has space just after { at path: "${data.path.join(".")}"`;
										newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "error", lineString: lineString });
									}
								});
								rightBracketAllIndeces.forEach((rightBracketIndex) => {
									if (data.value[rightBracketIndex - 1] === " ") {
										const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
										const text = `[${lineNumber}] Has space just before } at path: "${data.path.join(".")}"`;
										newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "error", lineString: lineString });
									}
								});
								if (data.value.includes(";")) {
									const chars = data.value.split("");
									leftBracketAllIndeces.forEach((leftBracketIndex, bracketIndex) => {
										const insideQuotes = chars.slice(leftBracketIndex, rightBracketAllIndeces[bracketIndex]);
										const semiColonIndex = insideQuotes.indexOf(";");
										const leftBracketToSemiColon = insideQuotes.slice(0, semiColonIndex);
										const semiColonToRightBracket = insideQuotes.slice(semiColonIndex + 1);
										if (insideQuotes[semiColonIndex + 1] === " ") {
											const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
											const text = `[${lineNumber}] Has space just after ; at path: "${data.path.join(".")}"`;
											newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "error", lineString: lineString });
										}
										if (leftBracketToSemiColon.includes(" ")) {
											const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
											const text = `[${lineNumber}] Has blankspace somewhere between { and ; at path: "${data.path.join(".")}"`;
											newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "error", lineString: lineString });
										}
										if (semiColonToRightBracket.length === 0) {
											const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
											const text = `[${lineNumber}] Has empty param between { and ; at path: "${data.path.join(".")}"`;
											newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "error", lineString: lineString });
										}
										if (insideQuotes[0] === "{" && insideQuotes[1] === ";" && insideQuotes.length === 2) {
											const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
											const text = `[${lineNumber}] Has empty object {;} at path: "${data.path.join(".")}"`;
											newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "error", lineString: lineString });
										}
									});
								}
							}
						}
						if (referenceFile?.data && referenceFile.data[dataIndex].key !== data.key) {
							if (numberOfDiffernces++ < 10) {
								const { lineNumber, lineString } = getLineDetailsJson(fileData.text, data.path);
								const text = `[${lineNumber}] Has different key wrt Orginal File at path: "${data.path.join(".")}"`;
								newFilesDiff.push({ fileIndex: fileDataIndex, text: text, severity: "error", lineString: lineString });
							} else if (!warningShown) {
								newFilesDiff.push({ severity: "error", fileIndex: fileDataIndex, text: `Reference and Update file ${fileDataIndex} ${fileData.file.name} have structural difference starting around the above keys` });
								warningShown = true;
							}
						}
					}
				} else {
					// wrong data type
				}
			});
		});

		setFilesDiff(newFilesDiff);
	};

	const handleResetClick = () => {
		if (referenceFileRef.current && updateFileRef.current) {
			referenceFileRef.current.value = "";
			updateFileRef.current.value = "";
		}

		setHasReferenceFileError(false);
		setReferenceFile(undefined);

		setHaveUpdateFilesError(false);
		setUpdateFiles([]);

		setFilesDiff([]);
	};

	const showHeading = (fileIndex: number): boolean => {
		if (filesHeadingShown.includes(fileIndex)) {
			return false;
		} else {
			filesHeadingShown.push(fileIndex);
			return true;
		}
	};

	return (
		<>
			<section>
				<Typography variant="h6" gutterBottom={true}>{t("localizationUtitlity.uploadFiles")}</Typography>
				<Paper style={{ marginBottom: "10px" }}>
					<label htmlFor="l10n-reference-file">
						<ListItemButton>
							<ListItemIcon>
								<icons.Upload color={hasReferenceFileError ? "error" : "primary"} />
							</ListItemIcon>
							<ListItemText
								primary={t("localizationUtitlity.reference")}
								secondary={referenceFile?.file.name ?? t("localizationUtitlity.noFileChosen")}
								slotProps={{
									primary: {
										color: hasReferenceFileError ? "error" : undefined,
									},
									secondary: {
										color: hasReferenceFileError ? "error" : undefined,
									},
								}}
							/>
						</ListItemButton>
					</label>
					<InputBase
						inputRef={referenceFileRef}
						id="l10n-reference-file"
						type="file"
						inputProps={{ accept: ".json" }}
						onChange={(event: ChangeEvent<HTMLInputElement>) => (handleFilesChanged("reference", event.target.files))}
						sx={{ display: "none" }}
					/>
				</Paper>
				<Paper style={{ marginBottom: "10px" }}>
					<label htmlFor="l10n-update-file">
						<ListItemButton>
							<ListItemIcon>
								<icons.Upload color={haveUpdateFilesError ? "error" : "primary"} />
							</ListItemIcon>
							<ListItemText
								primary={t("localizationUtitlity.update")}
								secondary={(updateFiles.length === 0) ? t("localizationUtitlity.noFileChosenMultiple") : updateFiles.map((fileData, index) => (`${index} ${fileData.file.name}`)).join(", ")}
								slotProps={{
									primary: {
										color: haveUpdateFilesError ? "error" : undefined,
									},
									secondary: {
										color: haveUpdateFilesError ? "error" : undefined,
									},
								}}
							/>
						</ListItemButton>
					</label>
					<InputBase
						inputRef={updateFileRef}
						id="l10n-update-file"
						type="file"
						inputProps={{ accept: ".json", multiple: true }}
						onChange={(event: ChangeEvent<HTMLInputElement>) => (handleFilesChanged("update", event.target.files))}
						sx={{ display: "none" }}
					/>
				</Paper>
			</section>
			<section>
				<Button
					variant="contained"
					disabled={hasReferenceFileError || haveUpdateFilesError || updateFiles.length === 0}
					onClick={handleTestClick}
				>
					{t("localizationUtitlity.test")}
				</Button>
				<Button
					variant="contained"
					color="neutral"
					disabled={!hasReferenceFileError && !haveUpdateFilesError && referenceFile === undefined && updateFiles.length === 0}
					onClick={handleResetClick}
					style={{ marginLeft: "10px" }}
				>
					{t("messages.reset")}
				</Button>
			</section>
			{(filesDiff.length > 0) &&
				<section>
					<Typography variant="h6" gutterBottom={true} style={{ marginTop: "16px" }}>{t("localizationUtitlity.output")}</Typography>
					{filesDiff.map((diff, index) => (
						<Fragment key={index}>
							{typeof diff.fileIndex === "number" && showHeading(diff.fileIndex) && <Typography variant="subtitle1">{`${diff.fileIndex} ${updateFiles[diff.fileIndex]?.file.name ?? ""}`}</Typography>}
							<Paper sx={{ p: 0.5, my: 1 }}>
								<MessageBlock style={{ color: (diff.severity === "warning") ? theme.palette.warning.main : theme.palette.error.main }}>{diff.text}</MessageBlock>
								{diff.lineString && <CodeBlock>{diff.lineString}</CodeBlock>}
							</Paper>
						</Fragment>
					))}
				</section>
			}
		</>
	);
};

export default LocalizationPage;
