import { useState, useEffect } from "react";
import { Paper, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// cmp
import LogsTabsContent from "../cmp/logs/LogsTabsContent";
// services
import Log from "../services/log";
// types
import type { WsType } from "../types/roc-ws";

const LogPage = () => {
	const [logTypes, setLogTypes] = useState<Array<WsType>>(Log.getLogTypes());
	const [selectedTab, setSelectedTab] = useState<WsType>(logTypes[0]);

	useEffect(() => {
		const handleLogTypeAdded = () => {
			setLogTypes(Log.getLogTypes());
		};

		Log.on("logTypeAdded", handleLogTypeAdded);

		return () => {
			Log.off("logTypeAdded", handleLogTypeAdded);
		};
	}, []);

	return (
		<TabContext value={selectedTab}>
			<Paper component="section" style={{ marginBottom: "12px" }}>
				<TabList onChange={(event, value: WsType) => (setSelectedTab(value))}>
					{logTypes.map((type) => (
						<Tab
							key={type}
							id={`tab-logs-${type}`}
							value={type}
							label={type}
						/>
					))}
				</TabList>
			</Paper>
			{logTypes.map((type) => (
				<TabPanel key={type} value={type}>
					<LogsTabsContent logType={type} />
				</TabPanel>
			))}
		</TabContext>
	);
};

export default LogPage;
