import { CircularProgress } from "@mui/material";
// cmps
import TEditorSelector from "../cmp/table-editor/TeditorSelector";
// contexts
import { useUserData } from "../context/UserDataContext";

const TEditorPage = () => {
	const { ready } = useUserData();

	if (!ready) {
		return <CircularProgress />;
	}

	return <TEditorSelector />;
};

export default TEditorPage;
