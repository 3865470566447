import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Paper, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// contexts
import { useUserData } from "../context/UserDataContext";
// cmp
import ExportBackup from "../cmp/backup/ExportBackup";
import ImportBackup from "../cmp/backup/ImportBackup";
// types
import type { ValueOf } from "type-fest";

const TAB_IDS = {
	EXPORT: "export",
	IMPORT: "import",
} as const;

type TabId = ValueOf<typeof TAB_IDS>;

const BackUpPage = () => {
	const { t } = useTranslation();
	const { ready } = useUserData();

	const [selectedTab, setSelectedTab] = useState<TabId>(TAB_IDS.EXPORT);

	if (!ready) {
		return <CircularProgress />;
	}

	return (
		<TabContext value={selectedTab}>
			<Paper component="section" style={{ marginBottom: "12px" }}>
				<TabList onChange={(event, value: TabId) => (setSelectedTab(value))}>
					<Tab
						id={`tab-backup-${TAB_IDS.EXPORT}`}
						label={t("backup.export")}
						value={TAB_IDS.EXPORT}
					/>
					<Tab
						id={`tab-backup-${TAB_IDS.IMPORT}`}
						label={t("backup.import")}
						value={TAB_IDS.IMPORT}
					/>
				</TabList>
			</Paper>
			<TabPanel value={TAB_IDS.EXPORT}><ExportBackup /></TabPanel>
			<TabPanel value={TAB_IDS.IMPORT}><ImportBackup /></TabPanel>
		</TabContext>
	);
};

export default BackUpPage;
