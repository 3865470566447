import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
	AppBar,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	ListItemAvatar,
	Toolbar,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Svg from "../cmp/svg";
// contexts
import { useServerOption } from "../context/ServerOptionContext";
// services
import User from "../services/user";

const AccountAddPage = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	const navigate = useNavigate();

	const serverOption = useServerOption();

	return (
		<AppBar>
			<Toolbar>
				<IconButton onClick={() => (navigate("/"))}><Svg src="navigation/arrowLeft.svg" color={theme.palette.primary.contrastText} /></IconButton>
				<Typography variant="h6" noWrap={true} style={{ marginLeft: "12px", width: "100%" }}>
					{t("account.addAccount")}
				</Typography>
				<List style={{ maxWidth: "300px", marginLeft: "auto", marginRight: "auto" }}>
					<ListItemButton onClick={() => (User.login())}>
						<ListItemAvatar><Svg src="navigation/userCircle" /></ListItemAvatar>
						<ListItemText primary={serverOption.label} secondary={serverOption.hostname} />
					</ListItemButton>
				</List>
			</Toolbar>
		</AppBar>
	);
};

export default AccountAddPage;
